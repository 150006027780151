import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import SideBySide from "../components/SideBySide"
import { graphql } from "gatsby"

const sideBySideFirstRow = [
  {
    name: "The Comfort Control2 System",
    description:
      "The Comfort Control2 System™ provides over 32 on-board diagnostics and fault history codes by detecting system and electrical problems. The integrated diagnostics with Active Protection™ prevents compressor operation when potentially harmful conditions are detected. It sends “Call for Service” alert notification to the thermostat to alert you of required service.",
  },
  {
    name: "Serial Communication Enhanced",
    description:
      "When installed with a Serial Communicating Air Handler and user interface control Series 500 thermostat this unit offers auto-configuration, diagnostic messaging and full communicating capability, to increase your home comfort even further.",
  },
  {
    name: "Legacy Enabled",
    description:
      "Unit can be conventionally wired using 24VAC with non-communicating Ruud Ultra air-handlers or furnaces with non-communicating thermostat. If you prefer to keep your old thermostat, in most cases, this A/C will allow you to do so.",
  },
  {
    name: "Operation",
    description:
      "A compressor sound blanket is standard to provide your unit with quieter unit operation. Units are equipped with a reliable Two-Stage compressor for precise temperature control with an On-Demand dehumidification feature. When matched with variable speed gas furnace or air-handler, the system adjusts airflow to help control humidity for unsurpassed comfort in the cooling mode.",
  },
  {
    name: "Low Profile",
    description:
      "The industries lowest profile design at just 33 inch [864mm] maximum height makes unit less noticeable in your yard and is easily installed leaving room for optional equipment and optimal access for maintenance.",
  },
  {
    name: "Cabinet and Design",
    description:
      "Attractive, louvered wrap-around jacket protects the coil from yard hazards and weather extremes. Top grille is steel reinforced for extra strength. Cabinet is powder painted for all weather protection. The units are constructed with painted galvanized steel cabinets for added reliability and durability. All controls are accessible by removing one service panel. Removable top grille provides access to the condenser fan motor and condenser coil, for an easier time with serviceability. Combination Grille/Motor Mount secures the motor to the underside of the discharge grille. The grille protects the motor windings and bearings from rain and snow.",
  },
  {
    name: "Durability",
    description:
      "All models meet or exceed a 1000-hour salt spray test per ASTM B117 Standard Practice for Operating Salt Spray Testing Apparatus, to ensure they can withstand tough weather conditions.",
  },
]
const sideBySideSecondRow = [
  {
    name: "The Comfort Control2 System",
    description:
      "Provides advanced service-ability with over 28 on-board diagnostics & fault history codes by detecting system & electrical problems. “Call for Service” alert notification is sent to the thermostat to alert the homeowner of required service. This includes dual seven segments LED & diagnostic history for easier system diagnostics & troubleshooting.",
  },
  {
    name: "Serial Communication Enhanced",
    description:
      "When installed with a Serial Communicating Condensing Unit & user interface control 500 Series thermostat this unit offers 4 or 2 wire installation, auto-configuration, & diagnostic messaging with full communicating capability.",
  },
  {
    name: "Motor and Blow Compartment",
    description:
      "Energy efficient & quiet ECM 3.0 communicating motor.  An insulated blower compartment, a slow-open gas valve, specially designed 2-speed induced draft motor & blower motor make it one of the most reliable & quietest furnaces on the market today.",
  },
  {
    name: "Modulating Function",
    description:
      "When used with a modulating thermostat, modulation rate between 40% and 100% of total capacity.",
  },
  {
    name: "Two-Stage Function",
    description:
      "When used with a two-stage thermostat, furnace operates at 40% on first stage, & stages up to 65%, then 100% for second stage.",
  },
  {
    name: "Multi-Stage Function",
    description:
      "When used with a single-stage thermostat, furnace functions as a three stage furnace operating at 40%, 65% & 100% of total capacity.",
  },
  {
    name: "Cabinet and Design",
    description:
      "The units are constructed with Pre-paint galvanized steel cabinets for added reliability & durability. Solid doors & easy door removal & replacement makes for an easier time with service-ability.",
  },
  {
    name: "Patented Heat Exchanger",
    description:
      "Primary heat exchanger constructed of aluminized steel and secondary heat exchanger constructed of stainless steel for maximum corrosion resistance and thermal fatigue reliability.",
  },
  {
    name: "Direct Spark Ignition & Remote Sensor",
    description:
      "Primary heat exchanger constructed of aluminized steel and secondary heat exchanger constructed of stainless steel for maximum corrosion resistance and thermal fatigue reliability. The direct spark ignition with reduced firing rates is an efficient method of gas ignition while the remote flame sensor monitors the flame. For added safety & personal peace of mind the direct spark ignition with remote sensor will lock out system after trial for ignition if main burner fails to ignite.",
  },
  {
    name: "Furnace Control",
    description:
      "For added customer assurance, all models feature an integrated board & are equipped with humidifier & electronic air cleaner hookups for added heating capacity & efficiency.",
  },
  {
    name: "Thermostat",
    description:
      "Thermostat is compatible with single or two-stage thermostats. (For optimal performance, two-stage thermostats are recommended.)  A variety of cooling coils & plenums designed to use with (Ruud Ultra Series™ & Super Quiet 80™), two stage gas furnaces are available as optional accessories.",
  },
  {
    name: "Air Quality and Control",
    description:
      "Units are equipped with a low speed constant fan & constant temperature rise for superior comfort. Along with constant CFM & Dip switch settings for sale",
  },
]

const sideBySideThirdRow = [
  {
    name: "Performance",
    description:
      "Built with our 360°+1 Philosophy for optimal Performance, Integration, Installability and Serviceability. Control from anywhere with systemwide remote wireless, air & water system management web portal and mobile applications",
  },
  {
    name: "Operation Modes",
    description:
      "Heat, Cool, Auto (includes Vacation mode), Emergency Heat and Fan Only",
  },
  {
    name: "Display",
    description: `Full-color, 4.7"x1" depth LCD touchscreen display. Attractive, modern design with interchangeable faceplates. Easy-to-read icons and text. Indoor/outdoor temperature display`,
  },
  {
    name: "Intuitive Programming",
    description:
      "Lifestyle-based scheduling with 1-touch mode selection (including 1-touch vacation mode). Auto-mode control automatically switches between Heat and Cool mode to maintain set temperature range. Continuous Fan Mode ensures even temperatures and continuous air filtering throughout home. Humidification (auto/manual) & Dehumidification. Plus Low Ambient Cooling",
  },
  {
    name: "Interchangeable Faceplates",
    description:
      "Shown with black, includes easy to change soft white and taupe",
  },
]

const Ruud = ({ data }) => {
  return (
    <Layout>
      <Seo title="Ruud" />
      <h1 className="pt-4 pb-2 my-4 text-3xl font-extrabold text-center text-blue sm:text-4xl">
        Ruud Air Conditioning and Furnaces
      </h1>
      <SideBySide
        image={data.row1Image}
        items={sideBySideFirstRow}
        imgRight
        header="High Efficiency Condensing Units"
        subheader="The Ruud Ultra Series™ High Efficiency Two-Stage UASL/UARL-JEC condensing unit was designed with performance in mind. These units offer comfort, energy conservation and dependability for single, multi-family and light commercial applications. The Ruud Ultra Series™ Two-Stage UASL/UARL- JEC condensing units are the result of an ongoing development program for improved efficiencies. With system SEER’s as high as 19.5, these units continue a tradition of delivering high efficiency. These units also contain the most advanced alternate refrigerant which contains no chlorofluorocarbons (CFCs), or hydro-chlorofluorocarbons (HCFCs), or other compounds that may leak from air conditioning systems and potentially harm the protective ozone layer of the Earth’s atmosphere."
      />
      <SideBySide
        image={data.row2Image}
        items={sideBySideSecondRow}
        header="Gas Furnaces"
        subheader="The Ruud RGGE, RGFG, and RGJF Ultra Series™ 90 Plus Communicating Modulating gas furnaces equipped with the Comfort Control2 System & Contour Comfort Control line of Upflow/Horizontal, & Dedicated Downflow, are designed for installation in closets, alcoves, utility rooms, or attics."
      />
      <SideBySide
        image={data.row3Image}
        items={sideBySideThirdRow}
        header="EcoNet Thermostat"
        imgRight
        subheader="The Ruud EcoNet™ Control Center, our intuitive, integrated air and water management system, customizes comfort while cutting energy costs*—from anywhere."
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query RuudPageQuery {
    row1Image: file(relativePath: { eq: "ruud.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    row2Image: file(relativePath: { eq: "ruud-furnace.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    row3Image: file(relativePath: { eq: "ruud-thermostat2.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`

export default Ruud
